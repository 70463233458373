import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { FirebaseService } from './firebase.service';

interface SafariNavigator extends Navigator {
  standalone?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  token: string = '';

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService,
    private platform: Platform // Adicione aqui
  ) {}

  private isPWA(): boolean {
    return (
      window.matchMedia('(display-mode: standalone)').matches || 
      ((window.navigator as SafariNavigator).standalone === true) || 
      document.referrer.includes('android-app://')
    );
  }


  async initPush() {
    if (this.isPWA()) {
      return await this.initPWAPush();
    } else if (!this.platform.is('cordova')) {
      return await this.initWebPush();
    }
    return false;
  }


  private async initPWAPush() {
    try {
      // Registrar o service worker específico para PWA
      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.register('/pwa-firebase-messaging-sw.js', {
          scope: '/'
        });

        await navigator.serviceWorker.ready;

        const permission = await Notification.requestPermission();
        
        if (permission === 'granted') {
          const token = await this.firebaseService.getMessaging().getToken({
            serviceWorkerRegistration: registration,
            vapidKey: 'BFxBgT4PKS4ieug9EWK0voaFUWOAp7KX3s_DWRn2DDU-PgN8stticwiDnZ1ML-0bjj5wGNTymf3wYYnoORUoraM'
          });

          if (token) {
            this.token = token;
            await this.saveTokenToServer(token);
            return true;
          }
        }
      }
      return false;
    } catch (error) {
      console.error('Erro ao inicializar push no PWA:', error);
      throw error;
    }
  }
  
  private async initWebPush() {
    try {
      console.log('1. Iniciando initWebPush');
      
      if ('serviceWorker' in navigator) {
        console.log('2. Registrando Service Worker...');
        try {
          const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
          console.log('3. Service Worker registrado:', registration);
          
          await navigator.serviceWorker.ready;
          console.log('4. Service Worker está ativo');
        } catch (swError) {
          console.error('Erro no Service Worker:', swError);
          throw swError;
        }
      }
  
      const permission = await Notification.requestPermission();
      console.log('5. Status da permissão:', permission);
  
      if (permission === 'granted') {
        console.log('6. Permissão concedida, gerando token...');
        
        try {
          await new Promise(resolve => setTimeout(resolve, 1000));
          
          const token = await this.firebaseService.getMessaging().getToken({
            vapidKey: 'BFxBgT4PKS4ieug9EWK0voaFUWOAp7KX3s_DWRn2DDU-PgN8stticwiDnZ1ML-0bjj5wGNTymf3wYYnoORUoraM'
          });
          console.log('7. Token gerado:', token);
            
          if (token) {
            this.token = token;
            console.log('8. Salvando token no servidor...');
            await this.saveTokenToServer(token);
            console.log('9. Token salvo com sucesso');
            return true;  // Token foi gerado e salvo com sucesso
          }
          return false;  // Token não foi gerado
        } catch (tokenError) {
          console.error('Erro ao gerar token:', tokenError);
          throw tokenError;
        }
      }
      return false;  // Permissão não foi concedida
    } catch (err) {
      console.error('Erro ao inicializar push:', err);
      throw err;
    }
  }
  
  private saveTokenToServer(token: string) {
    const userId = localStorage.getItem('userId');
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL.concat('push/save-token'), {
        token,
        user_id: userId
      }).subscribe({
        next: (response) => {
          console.log('Token salvo com sucesso:', response);
          resolve(true);  // Resolve com true quando salvar com sucesso
        },
        error: (error) => {
          console.error('Erro ao salvar token:', error);
          reject(error);
        }
      });
    });
  }


}