import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-extrato',
  templateUrl: './extrato.component.html',
  styleUrls: ['./extrato.component.scss'],
})
export class ExtratoComponent implements OnInit {

  user_id;
  user_name;
  pontos;
  moedas;
  numeros;
  data = new Date();

  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

  extratoCombinado = [];
  extrato:any = [];
  extrato_moedas:any = [];
  extrato_numeros:any = [];
  
  filtro_tipo = 'pontos';
  filtro = { data_inicio : null, data_fim : null};

  isModalOpen = false; 
  filterOptions = { pontos: false, moedas: false }; 
  exibirPontos: boolean = true;
  exibirMoedas: boolean = true;
  exibirNumeros: boolean = false;
  exibirQrcodes = false;
  extrato_qrcodes: any = [];


  constructor(private router:Router, private http: HttpClient) { }

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    let foto_src = localStorage.getItem('foto_src');
    if(foto_src){
      this.foto_src = foto_src;
    }

    this.obter_dados();
    this.counter_bonus();
    this.obter_extrato();
    this.obter_extrato_moedas();
    this.obter_extrato_numeros();
    this.obter_status_qrcodes();
    this.applyFilters();
  }
  

  
  toggleFilter(option: string) {
    this.filterOptions[option] = !this.filterOptions[option];
  }

  obter_dados(){
    
    this.http.get<any>(environment.API_URL.concat('pontos?id_participante='+this.user_id)).subscribe(data => {
      console.log(data);
      this.pontos = data.pontos;
      this.moedas = data.moedas;
      this.numeros = data.numeros;
    });

  }

  obter_extrato() {
    let url = 'pontos/obter_extrato/' + this.user_id + '?';
  
    if (this.filtro.data_inicio) {
      url = url + 'data_inicio=' + this.filtro.data_inicio + '&';
    }
    if (this.filtro.data_fim) {
      url = url + 'data_fim=' + this.filtro.data_fim;
    }
  
    this.http.get<any>(environment.API_URL.concat(url)).subscribe((data) => {
      if (data.status === 1) {
        this.extrato = data.extrato;
      } else {
        //console.log(data);
      }
    });
  }
  
  obter_extrato_moedas() {
    let url = 'pontos/obter_extrato_moedas/' + this.user_id + '?';
  
    if (this.filtro.data_inicio) {
      url = url + 'data_inicio=' + this.filtro.data_inicio + '&';
    }
  
    if (this.filtro.data_fim) {
      url = url + 'data_fim=' + this.filtro.data_fim;
    }
  
    this.http.get<any>(environment.API_URL.concat(url)).subscribe((data) => {
      if (data.status === 1) {
        this.extrato_moedas = data.extrato;
      } else {
        //console.log(data);
      }
    });
  }
  
  obter_extrato_numeros() {
    let url = 'pontos/obter_numeros_sorte/' + this.user_id + '?';
  
    if (this.filtro.data_inicio) {
      url = url + 'data_inicio=' + this.filtro.data_inicio + '&';
    }
  
    if (this.filtro.data_fim) {
      url = url + 'data_fim=' + this.filtro.data_fim;
    }
  
    this.http.get<any>(environment.API_URL.concat(url)).subscribe((data) => {
      this.extrato_numeros = data;
      console.log(data);
    });
  }

  obter_status_qrcodes() {
    this.http.get<any>(environment.API_URL + 'pontos/obter_status_qrcodes/' + this.user_id)
      .subscribe(data => {
        this.extrato_qrcodes = data;
      });
  }

  window = window;
  
  counter_bonus(){  
    let count_navigate = parseInt(localStorage.getItem('count_navigate')) + 1;
    localStorage.setItem('count_navigate', String(count_navigate));
  }



applyFilters() {

  this.exibirQrcodes = false;
  this.exibirMoedas = false;
  this.exibirPontos = false;
  if(this.filtro_tipo == 'pontos'){
    this.exibirPontos = true;
    this.exibirMoedas = false;
    this.exibirQrcodes = false;
  }
  else if(this.filtro_tipo == 'moedas'){
    this.exibirMoedas = true;
    this.exibirPontos = false;
    this.exibirQrcodes = false;
  }
  else if(this.filtro_tipo == 'qrcodes'){
    this.exibirQrcodes = true;
    this.exibirPontos = false;
    this.exibirMoedas = false;
  }


  this.closeModal();
}

  
  clearFilters() {
    this.filterOptions = { pontos: false, moedas: false };
    this.exibirPontos = true;
    this.exibirMoedas = false;
    this.exibirNumeros = false;
    
    this.closeModal();
  }

  toggleModal() {
    this.isModalOpen = !this.isModalOpen;
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }
}
